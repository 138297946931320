<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else>
      <div class="content-between">
        <h1 class="mr-sm-4 header-tablepage">PO - Purchase Order</h1>
        <div v-if="id != 0">
          Status :
          <span :class="textStatusClass(form.status_name)">{{
            form.status_name
          }}</span>
        </div>
      </div>
      <b-tabs class="mt-3">
        <b-tab title="Detail">
          <div class="bg-white">
            <div class="title-tabs">Detail</div>
            <div class="p-3">
              <b-row>
                <b-col cols="12" md="6">
                  <InputText
                    placeholder="PO No."
                    textFloat="PO No."
                    disabled
                    v-model="form.po_no"
                  />
                </b-col>
                <b-col cols="12" md="6">
                  <InputSelect
                    placeholder="Manufacturer"
                    :disabled="form.status_id > 1"
                    title="Manufacturer"
                    v-model="form.brand_id"
                    valueField="id"
                    textField="name"
                    :options="listmanufacturer"
                    :v="$v.form.brand_id"
                    :isValidate="$v.form.brand_id.$error"
                    :isRequired="true"
                  >
                    <template v-slot:option-first>
                      <b-form-select-option :value="null" disabled
                        >-- Select Menufacturer --</b-form-select-option
                      >
                    </template>
                  </InputSelect>
                </b-col>
                <b-col cols="12" md="6">
                  <InputSelect
                    v-model="form.destination_branch_id"
                    placeholder="Destination Branch"
                    title="Destination Branch"
                    valueField="id"
                    :disabled="form.status_id > 1"
                    textField="name"
                    :options="branchList"
                    :v="$v.form.destination_branch_id"
                    :isValidate="$v.form.destination_branch_id.$error"
                    :isRequired="true"
                  >
                    <template v-slot:option-first>
                      <b-form-select-option :value="null" disabled
                        >-- Select Destination Branch --</b-form-select-option
                      >
                    </template>
                  </InputSelect>
                </b-col>
                <b-col cols="12" md="6">
                  <InputText
                    v-model="form.created_by"
                    placeholder="Document Issuer"
                    textFloat="Document Issuer"
                    disabled
                  />
                </b-col>
                <b-col cols="12" md="6">
                  <InputDatePickerFilter
                    @input="
                      form.order_date > form.due_date
                        ? (form.due_date = '')
                        : (form.due_date = form.due_date)
                    "
                    :v="$v.form.order_date"
                    :isValidate="$v.form.order_date.$error"
                    textFloat="Issue date (DD/MM/YYYY)"
                    :disabled="form.status_id > 1"
                    name="valid-form"
                    ref="valid-form"
                    format="dd/MM/yyyy "
                    v-model="form.order_date"
                    type="date"
                    :isRequired="true"
                  />
                </b-col>
                <b-col cols="12" md="6">
                  <InputDatePickerFilter
                    v-model="form.due_date"
                    textFloat="Delivery Date (DD/MM/YYYY)"
                    :disabled="form.status_id > 2"
                    :isRequired="form.status_id == 1"
                    name="valid-form"
                    :min-datetime="form.order_date"
                    ref="valid-form"
                    format="dd/MM/yyyy "
                    type="date"
                    @input="updateDuedate"
                    :v="$v.form.due_date"
                    :isValidate="$v.form.due_date.$error"
                  />
                </b-col>

                <b-col cols="12">
                  <div>
                    <div class="title-tabs my-3">Product List</div>
                    <!-- Select Product -->

                    <b-row class="mb-2">
                      <b-col cols="2" sm="6" md="6" class="mt-2">
                        <b-button
                          v-if="form.status_id < 2"
                          @click.prevent="selectProduct"
                          class="btn-filter mr-2"
                        >
                          <span class="d-none d-sm-flex align-items-center">
                            <span class="mr-2">Select Items </span>
                            <font-awesome-icon
                              icon="chevron-right"
                              class="pointer"
                            />
                          </span>
                          <font-awesome-icon
                            icon="chevron-right"
                            class="d-sm-none"
                          />
                        </b-button>
                        <span class="text-black"
                          >Selected {{ this.allItem.length }} list
                          <span class="text-error">*</span></span
                        >
                        <div v-if="$v.form.products.$error" class="text-error">
                          Please select value.
                        </div>
                      </b-col>
                      <b-col cols="10" sm="6" md="6" class="mt-2">
                        <b-input-group class="mb-2">
                          <b-form-input
                            type="text"
                            id="header-search-bar"
                            class="search-bar"
                            @keyup.enter="handleSearch"
                            placeholder="Search Product"
                            v-model="filter.search"
                          >
                          </b-form-input>
                          <b-input-group-append is-text>
                            <b-iconstack
                              font-scale="2"
                              type="submit"
                              @click="handleSearch"
                            >
                              <b-icon
                                stacked
                                icon="search"
                                scale="0.5"
                                variant="grey"
                              ></b-icon>
                            </b-iconstack>
                          </b-input-group-append>
                        </b-input-group>
                      </b-col>
                    </b-row>
                    <b-table
                      responsive
                      striped
                      hover
                      :fields="fields"
                      :items="items"
                      :busy="isBusy"
                      show-empty
                      empty-text="No matching records found"
                    >
                      <template v-slot:table-busy>
                        <div class="text-center text-black my-2">
                          <b-spinner class="align-middle"></b-spinner>
                          <strong class="ml-2">Loading...</strong>
                        </div>
                      </template>
                      <template v-slot:cell(product_image)="{ item }">
                        <div class="position-relative picture-text pic-table">
                          <div
                            v-if="item.product_image"
                            class="pic-box"
                            v-bind:style="{
                              'background-image':
                                'url(' + item.product_image + ')',
                            }"
                          ></div>
                          <div v-else>
                            <img
                              class="pic-box pt-0"
                              :src="default_image"
                              alt="default Image"
                              srcset=""
                            />
                          </div>
                        </div>
                      </template>
                      <template v-slot:cell(price)="{ item }">
                        {{ item.price | numeral("0,0.00") }}
                      </template>
                      <template v-slot:cell(total_price)="{ item }">
                        {{ item.total_price | numeral("0,0.00") }}
                      </template>
                      <template
                        v-slot:cell(manufacturer_product_code)="{ item, index }"
                      >
                        <div
                          class="d-flex justify-content-center align-items-center text-center"
                        >
                          <InputText
                            placeholder="Manufacturer Code"
                            class="mr-1 mb-0 input-quantity"
                            disabled
                            v-model="item.manufacturer_product_code"
                          ></InputText>
                          <font-awesome-icon
                            v-if="form.status_id < 3"
                            class="check-circle pointer text-warning ml-1"
                            icon="pencil-alt"
                            @click="openModalManufacturer(item)"
                          />
                        </div>
                      </template>
                      <template v-slot:cell(quantity)="{ item, index }">
                        <div
                          class="d-flex justify-content-center align-items-center text-center"
                        >
                          <font-awesome-icon
                            v-if="form.status_id < 2"
                            class="check-circle pointer mr-1"
                            icon="minus-circle"
                            @click="handleProductQuantity(0, item)"
                          />
                          <InputText
                            placeholder="จำนวน"
                            type="number"
                            :disabled="form.status_id > 1"
                            @onKeyup.enter="
                              handleProductQuantityInput($event, item)
                            "
                            @blur="handleProductQuantityInput($event, item)"
                            class="mx-1 input-quantity mb-0"
                            v-model="item.quantity"
                          ></InputText>

                          <font-awesome-icon
                            v-if="form.status_id < 2"
                            class="check-circle pointer ml-1"
                            icon="plus-circle"
                            @click="handleProductQuantity(1, item)"
                          />
                        </div>
                      </template>
                      <template v-slot:cell(action)="{ item }">
                        <div v-if="form.status_id < 2">
                          <b-button
                            variant="icon"
                            class="px-0"
                            @click="deleteProduct(item.product_id)"
                          >
                            <b-icon icon="trash-fill"></b-icon>
                          </b-button>
                        </div>
                        <div v-else-if="form.status_id < 4">
                          <span
                            @click="openModalCheckInvoice(item)"
                            class=""
                            :class="{
                              'text-link':
                                item.received_available > 0 &&
                                form.status_id < 3,
                            }"
                            >Acceptance</span
                          >
                        </div>
                      </template>
                    </b-table>
                  </div>
                </b-col>
                <b-col cols="12">
                  <Pagination
                    @handleChangeTake="handleChangeTake"
                    :pageOptions="pageOptions"
                    :filter="filter"
                    :rows="rows"
                    @pagination="pagination"
                  />
                </b-col>
                <b-col cols="12">
                  <InputTextArea
                    textFloat="Note"
                    :disabled="form.status_id > 1"
                    type="text"
                    class="mt-2 custom-input"
                    placeholder="Note"
                    rows="3"
                    name="message"
                    v-model="form.remark"
                /></b-col>
              </b-row>
            </div>
          </div>

          <FooterAction
            v-if="id == 0"
            saveText="Open PO"
            @submit="checkForm(0)"
            routePath="/purchase-order"
          />
          <FooterAction
            v-else-if="form.status_id == 1"
            saveText="Approve"
            @customAction="checkForm(0)"
            customText="Save"
            cancelText="Not Approved"
            backAction
            @routePath="openModalConfirm"
            @submit="checkForm(1)"
            routePath="/purchase-order"
          />

          <FooterAction
            v-else-if="form.status_id == 2"
            saveText="Transfer"
            customText="Close PO"
            :disabledCustom="
              items.find((x) => x.received_quantity > 0) ? true : false
            "
            @customAction="checkForm(4)"
            @submit="openmodalTranfer()"
            routePath="/purchase-order"
          />
          <FooterAction v-else hideSubmit routePath="/purchase-order" />
        </b-tab>
        <b-tab title="History" :disabled="id == 0">
          <Logs ref="logPo"></Logs>
        </b-tab>
      </b-tabs>
    </div>
    <ModalSelectProduct
      :isApprove="isApprove"
      ref="modalSelectProduct"
      :selectedItem="form.products"
      @success="handleSelectProduct"
    ></ModalSelectProduct>

    <ModalManufacturer
      ref="modalManufacturer"
      @updateProduct="updateProduct"
    ></ModalManufacturer>

    <ModalCheckInvoice
      :issueDate="form.order_date"
      ref="modalCheckInvoice"
      @updateReceiveProduct="updateReceiveProduct"
      :form="form.products"
    ></ModalCheckInvoice>
    <ModalTranfer ref="modalTranfer" @confirmCreate="getData()"></ModalTranfer>
  </div>
</template>

<script>
import ModalSelectProduct from "./components/ModalSelectProduct.vue";
import ModalTranfer from "./components/ModalTranfer.vue";

import ModalManufacturer from "./components/ModalManufacturer.vue";
import { required, requiredIf } from "vuelidate/lib/validators";
import OtherLoading from "@/components/loading/OtherLoading.vue";
import ModalCheckInvoice from "./components/ModalCheckInvoice.vue";
import InputTextArea from "@/components/inputs/InputTextArea.vue";
import Logs from "./logs.vue";
export default {
  components: {
    ModalSelectProduct,
    OtherLoading,
    ModalManufacturer,
    ModalCheckInvoice,
    ModalTranfer,
    InputTextArea,
    Logs,
  },
  data() {
    return {
      isLoading: false,
      isBusy: false,
      title: "",
      id: this.$route.params.id,
      form: {
        po_id: null,
        brand_id: null,
        manufacturer_id: null,
        destination_branch_id: null,
        order_date: "",
        due_date: "",
        remark: "",
        products: [],
        status_id: 0,
      },
      fields: [
        { label: "Image", key: "product_image", thStyle: { width: "10%" } },

        { label: "Barcode", key: "barcode", thStyle: { width: "20%" } },
        {
          label: "Manufacturer Code",
          key: "manufacturer_product_code",
          thStyle: { width: "10%" },
        },
        {
          label: "Quantity",
          key: "quantity",
          thStyle: { width: "10%" },
        },
        { label: "Price", key: "price", thStyle: { width: "10%" } },
        { label: "Total Price", key: "total_price", thStyle: { width: "10%" } },
        {
          label: "Received",
          key: "received_quantity",
          thStyle: { width: "10%" },
        },
        {
          label: "Transfered",
          key: "transfered_quantity",
          thStyle: { width: "10%" },
        },

        { label: "Action", key: "action", thStyle: { width: "10%" } },
      ],
      filter: {
        search: "",
        page: 1,
        take: 10,
      },

      pageOptions: [
        { value: 5, text: "5 / page" },
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      items: [],
      rows: 0,
      allItem: [],
      filteredItems: [],
      searchDisplayProduct: "",
      isEdit: false,
      listmanufacturer: [],
      branchList: [],
      isApprove: false,
      form_receive: [],
    };
  },
  async created() {
    await this.getListmanufacturer();
    await this.getData();
    await this.getBranchList();
  },
  validations: {
    form: {
      due_date: {
        required: requiredIf(function () {
          return this.form.status_id == 1;
        }),
      },
      order_date: { required },
      brand_id: { required },
      destination_branch_id: { required },
      products: { required },
    },
  },
  methods: {
    async getData() {
      if (this.id < 1) {
        return;
      }
      this.isLoading = true;
      this.isBusy = true;

      const res = await this.axios(`/po/${this.id}`);

      if (res.data.result == 1) {
        this.form = res.data.detail;
        if (this.form.status_id == 1) {
          this.isApprove = true;
        }
        this.items = res.data.detail.products;

        this.form.products = [...this.items];
        this.allItem = this.items;
        this.filteredItems = [...this.allItem];
        this.rows = this.allItem.length;
        this.updateDisplayedItems();
      }
      this.isLoading = false;
      this.isBusy = false;
    },
    async getListmanufacturer() {
      const res = await this.axios(`/Brand/GetListBrandOption`);
      this.listmanufacturer = res.data.detail;
    },
    async getBranchList() {
      const res = await this.axios(`/branch/listAll`);
      this.branchList = res.data.detail;
    },
    selectProduct() {
      this.$refs.modalSelectProduct.show(true);
    },
    handleSelectProduct(items) {
      if (items) {
        this.allItem = items;
      }

      this.isBusy = true;
      const maxItems = this.filter.take;
      if (this.allItem.length > maxItems) {
        this.items = this.allItem.slice(0, maxItems);
      } else {
        this.items = this.allItem;
      }
      this.rows = items.length;
      this.form.products = items;
      this.filteredItems = items;
      this.isBusy = false;
    },

    pagination(val) {
      this.filter.page = val;
      this.updateDisplayedItems();
    },
    handleChangeTake() {
      this.filter.page = 1;
      this.updateDisplayedItems();
    },
    updateDisplayedItems() {
      this.isBusy = true;
      const start = (this.filter.page - 1) * this.filter.take;
      const end = start + this.filter.take;

      this.items = this.filteredItems.slice(start, end);
      this.rows = this.filteredItems.length;

      this.isBusy = false;
    },
    async deleteProduct(deleteId) {
      this.allItem = this.allItem.filter((x) => x.product_id !== deleteId);
      this.filteredItems = this.filteredItems.filter(
        (x) => x.product_id !== deleteId
      );
      this.form.products = this.form.products.filter(
        (x) => x.product_id !== deleteId
      );
      this.filter.page = 1;
      this.updateDisplayedItems();
    },
    handleSearch() {
      const searchText = this.filter.search.toLowerCase();
      if (searchText) {
        this.filteredItems = this.allItem.filter((item) =>
          item.barcode.toLowerCase().includes(searchText)
        );
      } else {
        this.filteredItems = [...this.allItem];
      }
      this.filter.page = 1;
      this.updateDisplayedItems();
    },
    handleProductQuantity(isPlus, item) {
      if (isPlus) {
        item.quantity += 1;
        item.total_price += item.price;
      } else if (item.quantity > 1) {
        item.quantity -= 1;
        item.total_price -= item.price;
      } else {
        return;
      }
    },
    handleProductQuantityInput(event, item) {
      if (!item.quantity) {
        item.quantity = 1;
        item.total_price = 1 * item.price;
        return;
      }
      item.total_price = item.quantity * item.price;
    },
    async checkForm(type) {
      const save = async (params) => {
        //save
        if (!type) {
          this.$v.form.products.$touch();
          if (this.$v.form.products.$error) {
            return;
          }
          this.$bus.$emit("showLoading");
          let payload = {
            po_id: this.id,
            ...this.form,
            is_approve: null,
          };
          const res = await this.axios.post(`/po/save`, payload);
          if (res.data.result === 1) {
            this.successAlert();
            this.$router.push(`/purchase-order`);
          } else {
            this.errorAlert(res.data.message);
          }
        }
        //save Approve, Reject
        else if (type == 1 || type == 3) {
          this.$v.form.$touch();
          if (this.$v.form.$error) {
            return;
          }
          this.$bus.$emit("showLoading");
          let payload = {
            po_id: this.id,
            ...this.form,
            is_approve: type == 1 ? true : false,
          };
          const res = await this.axios.post(`/po/save`, payload);
          if (res.data.result === 1) {
            this.successAlert();
            this.$router.push(`/purchase-order`);
          } else {
            this.errorAlert(res.data.message);
          }
        }
        //close po
        else if (type === 4) {
          this.$v.form.$touch();
          if (this.$v.form.$error) {
            return;
          }
          this.$bus.$emit("showLoading");
          let payload = { po_id: this.id };
          const res = await this.axios.post(`/po/ClosePO`, payload);
          if (res.data.result === 1) {
            this.successAlert();
            this.$router.push(`/purchase-order`);
          } else {
            this.errorAlert(res.data.message);
          }
        }
        this.$bus.$emit("hideLoading");
      };
      if (type == 0) return save();
      let message = "";
      if (type == 1) {
        message = "Do you want to approve this PO ?";
      } else if (type == 3) {
        message = "Do you want to reject this PO ?";
      } else if (type == 4) {
        message = "Do you want to close this PO ?";
      }
      this.confirmAlert({ message: message }).then(async ({ isConfirmed }) => {
        if (isConfirmed) {
          save();
        }
      });
    },
    openModalManufacturer(item) {
      this.$refs.modalManufacturer.showModal(item);
    },
    async updateProduct(item) {
      let index = this.form.products.findIndex(
        (x) => x.product_id == item.product_id && item.id == x.id
      );

      this.form.products[index].manufacturer_product_code =
        item.manufacturer_product_code;
      this.form.products[index].remark = item.remark;
      if (this.form.status_id == 2) {
        let payload = {
          po_id: this.id,
          po_product_id: item.id,
          manufacturer_product_code: item.manufacturer_product_code,
          remark: item.remark,
        };
        const res = await this.axios.post(
          `/po/UpdateManufacturerProductCode`,
          payload
        );
        this.$refs.logPo.getList();
      }
    },
    openModalCheckInvoice(item) {
      if (item.received_available <= 0 || this.form.status_id > 2) {
        return;
      }
      this.$refs.modalCheckInvoice.showModal(item);
    },
    async updateReceiveProduct() {
      await this.getData();
    },
    openmodalTranfer() {
      if (!this.form.main_branch_id) {
        this.errorAlert("Please select Main Branch before transfer product.");
      } else if (this.form.destination_branch_id !== this.form.main_branch_id) {
        this.$refs.modalTranfer.showModal(0, this.form.main_branch_id);
      } else {
        this.$refs.modalTranfer.showModal(1, this.form.main_branch_id);
      }
    },
    openModalConfirm() {
      // this.confirmAlert({
      //   message: "Do you want to reject this PO ?",
      //   // title: "Success !",
      //   // icon: "success",
      //   confirmButtonText: "Confirm",
      //   cancelButtonText: "Close",
      // }).then((val) => {
      // if (val.isConfirmed) {
      this.checkForm(3);
      // }
      // });
    },
    textStatusClass(status) {
      switch (status) {
        case "สำเร็จ":
          return "text-success";
        case "ยกเลิก":
          return "text-error";
        default:
          return "text-warning";
      }
    },
    async updateDuedate(val) {
      if (this.form.status_id > 1) {
        this.$bus.$emit("showLoading");
        let dateObj = new Date(val);
        let month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
        let day = dateObj.getDate().toString().padStart(2, "0");
        let year = dateObj.getFullYear();

        let formattedDate = `${day}/${month}/${year}`;
        let payload = {
          po_id: this.id,
          due_date: formattedDate,
        };
        await this.axios.put(`/po/PoDeliveryDate`, payload);
        this.$bus.$emit("hideLoading");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.check-circle {
  width: 15px;
  height: 15px;
}
.input-quantity ::v-deep input {
  text-align: center !important;
}
</style>
